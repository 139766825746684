import React, { useEffect } from "react"
import SEO from "../components/seo"
import Header from "../components/Navbar/Navbar.js"
import Footer from "../components/Footer/Footer.js"
import BlogHeader from "../components/Blog/BlogHeader"
import BlogContent from "../components/Blog/BlogContent"
import { scrollTop } from "../components/layout"
import Scroll from "../components/ScrollTop/Scroll"
import BlogRelatedArticle from "../components/Blog/BlogRelatedArticle.js"
import ContactForm from "../components/Contact/contactForm/ContactForm.js"
import FBShareButton from "../components/Sharing/Sharing.js"

const BlogTemplate = ({ pageContext }) => {
  const blog = pageContext.blogData
  const relatedArticleData = pageContext.relatedArticleData;
  const { seo } = blog
  const blogUrl = typeof window !== "undefined" ? window.location.href : "/";

  useEffect(()=> {
    if(checkNewTOC()) {
      setClassForToCItemOnScroll();
      setClassForToCItemOnClick();
      setSideMenuPositionOnScroll();
    }
  }, [])

  const checkNewTOC = () => {
    var toc = document.querySelectorAll(".blog-content__toc");
    return toc.length > 0 ? true : false;
  }

  const toggleForm = () => {
    var button = document.querySelector(".blog-content__form-dropdown-button");
    button.classList.toggle("collapsed");

    var form = document.querySelector("#content-form form");
    form.classList.toggle("collapsed");

    var toc = document.querySelectorAll(".blog-content__toc");
    if(toc.length > 0) {
      toc[0].classList.toggle("collapsed-form");
    }
  }

  return (
    <>
      <SEO
        type="article"
        title={seo.title || blog.title}
        description={seo.metaDesc}
        metaKeywords={blog.focuskw}
        thumbnail={blog.featuredImage?.node.sourceUrl}
      />
      <Header showHeaderBG={true} />
      <div id="blog">
            <div className="blog-area">
                <BlogHeader
                    title={blog.title}
                    author={blog.author.node}
                    currentCategory={blog.categories.nodes}
                    thumbnail = {blog.featuredImage?.node.sourceUrl}
                />
                <div id="blog-content" className="wrapper align-items-start" style={{position: "relative"}}>
                  <BlogContent content={blog.content} featuredImage={blog.featuredImage?.node.sourceUrl} author={blog.author.node} customCTA={blog.customCTA} />

                  <div id="content-form" className="blog-content__form collapsed">
                    <FBShareButton url={blogUrl}/>
                    <button className="blog-content__form-dropdown-button" onClick={()=>toggleForm()}>Talk To Expert</button>
                    <ContactForm miniForm={true}/>
                  </div>
                </div>

                <div className="wrapper mt-4">
                    <BlogRelatedArticle relatedArticles={relatedArticleData} currentArticle={blog} currentCategory={blog.categories.nodes}/>
                </div>
            </div>
      </div>
      <Footer />
      <Scroll showBelow={scrollTop} />
    </>
  )
}

export default BlogTemplate

function setSideMenuPositionOnScroll() {
  const triggerPos = 450;

  var articleBottom = document.querySelector(".blog-content").offsetHeight;
  var form = document.querySelector("#content-form");
  var toc = document.querySelector(".blog-content__toc");

  window.addEventListener("scroll", function() {
    if(document.documentElement.scrollTop > triggerPos && document.documentElement.scrollTop <= articleBottom) {
      form.classList.add("--fixed-pos");
      toc.classList.add("--fixed-pos");
      form.classList.remove("--slide-top");
      toc.classList.remove("--slide-top");
    } else if(document.documentElement.scrollTop > articleBottom) {
      form.classList.add("--slide-top");
      toc.classList.add("--slide-top");
    }
    else {
      form.classList.remove("--fixed-pos", "--slide-top");
      toc.classList.remove("--fixed-pos", "--slide-top");
    }
  })
}

function getTOCHeadings() {
  var headings = document.getElementsByClassName("blog-content__toc-item");
  var tocHeadings = [];

  for(var i = 0; i < headings.length; i++) {
    tocHeadings.push(headings[i]);
  }

  return tocHeadings;
}

function getSectionID(sectionTOC) {
  return sectionTOC.getAttribute("href").replace("#","");
}

function setClassForToCItemOnScroll() {
  var tocHeadings = getTOCHeadings();

  window.addEventListener("scroll", function() {
    tocHeadings.forEach( tocHeading => {
      const offset = 530;
      var sectionID = getSectionID(tocHeading);

      var section = document.getElementById(sectionID);

      if(typeof(section) != 'undefined' && section != null){
        var sectionPos = document.getElementById(sectionID).offsetTop + offset;
        var anchorEl = document.querySelector("a[href=\"#" + getSectionID(tocHeading) + "\"");

        if(document.documentElement.scrollTop >= sectionPos) {
          tocHeadings.forEach( toc => {
            toc.classList.remove("active");
          });
          anchorEl.classList.add("active");
        }
      }
    });
  })
}

function setClassForToCItemOnClick() {
  const offset = 530;
  var anchorEls = document.querySelectorAll(".blog-content__toc-item");

  anchorEls.forEach(anchor => {
    anchor.addEventListener("click", function(e) {
      e.preventDefault();

      var sectionID = getSectionID(anchor);
      var sectionPos = document.getElementById(sectionID).offsetTop + offset;
      window.scrollTo(0, sectionPos);
    });
  });
}
