import React from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,

  LinkedinIcon
} from "react-share"
import "./Sharing.scss"

const FBShareButton = props => {
  const {url} = props;

  const copyCurrentLink = url => {
    navigator.clipboard.writeText(url);

    var toolTip = document.getElementById("link-tool-tip");
    toolTip.classList.toggle("show");
    setTimeout(function(){toolTip.classList.toggle("show");},500);
  }

  return (
    <div className="share-icon-wrapper">
      <span className="share-text">Share</span>
      <FacebookShareButton url={url} >
        <img className="share-fb-icon" src={`${process.env.GATSBY_CDN_CONFIG}/v2/blog/ic_baseline-facebook.png`}/>
      </FacebookShareButton>

      <LinkedinShareButton url={url}>
        <img className="share-linkedin-icon" src={`${process.env.GATSBY_CDN_CONFIG}/v2/blog/mdi_linkedin.png`}/>
      </LinkedinShareButton>

      <TwitterShareButton url={url}>
        <img className="share-x-icon" src={`${process.env.GATSBY_CDN_CONFIG}/v2/blog/simple-icons_x.png`}/>
      </TwitterShareButton>

      <button class="react-share__ShareButton" style={{position:"relative", backgroundColor: "transparent", border: "none", padding: "0px", font: "inherit", color: "inherit" , cursor: "pointer"}} onClick={() => copyCurrentLink(url)}>
        <img className="share-link-icon" src={`${process.env.GATSBY_CDN_CONFIG}/v2/blog/link-icon.png`}/>
        <i id="link-tool-tip" className="tooltip">Copied link</i>
      </button>
    </div>
  )
}

export default FBShareButton
