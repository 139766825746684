import React from "react"
import "./Blog.scss"
import ArticleCard from "../Article/Article"
import CategoryCard from "../Cards/CategoryCard"

const BlogRelatedArticle = (props) => {
    const relatedArticles = props.relatedArticles.filter((item, index) => {
        return item.title !== props.currentArticle.title
    }).slice(0, 7);

    const articleData = SetRelatedArticles(relatedArticles, props);
    const firstArticleData = articleData[0];

    return(
        <>
            <div className="blog-related">
                <h2 className="text-center --no-uppercase mb-5">Related Articles</h2>

                <article className="firstArticle">
                    <ArticleCard item={firstArticleData} index={1} />
                </article>

                <div className="row m-minus-15 mb-5">
                {
                    articleData.slice(1).map((blogData, index) => {
                    return (
                        <div key={index} className="col-md-4 col-12 blog-custom-card">
                        <CategoryCard item={blogData} key={index} />
                        </div>
                    )
                    })
                }
                </div>
            </div>
        </>
    )
}

export default BlogRelatedArticle

function SetRelatedArticles(relatedArticles, props) {
    const articles = relatedArticles.map(({ ...post }, index) => {
        const featuredImage = post.featuredImage?.node?.sourceUrl;
        const thumbnail = featuredImage && featuredImage.indexOf("/wp-content/") === 0
            ? `https://neurond-wp.azurewebsites.net/${featuredImage}`
            : featuredImage;
        const categorySlug = props.currentCategory.map(node => node.slug);

        if (index === 0) {
            return {
              h2Title: post.title,
              description: [post.excerpt],
              imgLink: thumbnail,
              isReadMore: true,
              slug: post.slug,
              date: post.date,
              author: post.author.node,
              isBadge: true,
              categorySlug,
              categoryData: props.currentCategory
            }
          }
          return {
            ...post,
            thumbnail,
            date: post.date,
            categorySlug,
            isBadge: true,
            shortContent: null,
            categoryData: props.currentCategory
          }
    })
    return articles
}
