import React from "react"
import "./Blog.scss"
import categoryData from "../../data/categories.banner.json"
import { Link } from "gatsby"
import Badge from "../Badge/Badge"

const BlogHeader = props => {
const { title, author, currentCategory, thumbnail } = props
let matchedCategory = categoryData.find(x => x.slug === currentCategory[0].slug) || categoryData[0]
  return (
    <>
    <div className="blog-header">
        <div className="wrapper align-items-start">
            <Badge badgeData={currentCategory[0]}/>

            <div className="blog-header__title mt-4">
                <h1 id="heading-title-blog" className="text-white">
                    {title}
                </h1>
            </div>

            <div className="blog-header__author__info row align-items-center">
                <img className="blog-content__author-img img-fluid" width={70} src={author.avatar.url} alt={"Author avatar: " | author.name}/>
                <div className="col-10">
                    <p className="blog-header__author__info__name pb-2">{author.name}</p>
                    <p className="blog-header__author__info__occupation">{author.occupation.occupation}</p>
                </div>
            </div>
        </div>
    </div>

    <div className="wrapper">
        <div className="blog-header__navigation">
            <Link to="/">
                <i className="blog-header__home-icon"></i> Home
            </Link>{" "}
            <span className="blog-header__navigation__customSymbol">{">"}</span>{" "}
            <Link to="/blog">Blog</Link>{" "}
            <span className="blog-header__navigation__customSymbol">{">"}</span>{" "}
            <Link to={`/categories/${matchedCategory.slug}`}>
                {matchedCategory.title}
            </Link>{" "}
            <span className="blog-header__navigation__customSymbol">{">"}</span>{" "}
            {title}
        </div>
    </div>
    </>
  )
}

export default BlogHeader
