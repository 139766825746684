import React from "react"
import "./Blog.scss"
import "./content-styles.css"
import ButtonContact from "../BannerFullScreen/ButtonContact"

const BlogContent = props => {
    const { content, featuredImage, author, customCTA } = props
    const convertNewContent = content?.replace(/\/wp-content\/uploads\//g,
        `${process.env.PUBLIC_IMAGE_URL}/wp-content/uploads/`)
    const imgLink = featuredImage ||`${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/logo/neurond-final-white.svg`
    return (
        <>
            <div className="blog-content ck-content">
                <img className="blog-content__featured-image mb-5" src={imgLink} alt={"Featured image"}/>

                <div className="ck-zone" dangerouslySetInnerHTML={{ __html: convertNewContent }} />

                <div className="blog-content__author-wrapper row m-minus-15 my-5">
                    <div className="col-2">
                        <img className="blog-content__author-img img-fluid" src={author.avatar.url} alt={author.name}/>
                    </div>
                    <div className="col-10 px-0">
                        <p className="blog-content__author-name">{author.name}</p>
                        <p className="blog-content__author-description">{author.description}</p>
                    </div>
                </div>
            </div>
            {
                customCTA.customCtaText && (
                <div className="wrapper blog-cta">
                    <div className="row m-minus-15 blog-cta__content align-items-end">
                        <div id="cta-box" className="offset-md-1 col-lg-5 col-md-6 col-12">
                            <h2>{customCTA.customCtaText}</h2>
                        </div>
                        <div className="offset-md-2 offset-lg-3 col-md-2 col-12 mt-4 mt-md-0 text-center">
                            <ButtonContact item={{customClass:"--bg-yellow", customText:"Let's Talk", linkTo: `${customCTA.customCtaUrl}`}}/>
                        </div>
                    </div>
                </div>
                )
            }


            <hr />
        </>
    )
}

export default BlogContent
